import axios from "axios";
import store from "../ReduxToolkit/Store";

const API_URL = store.getState().apiUrl.url;

const getToken = () => {
  const userData = JSON.parse(localStorage.getItem("M2C:userData") || "{}");
  return userData?.token;
};

const getAuthHeaders = () => ({
  headers: {
    Authorization: `Bearer ${getToken()}`,
  },
});

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 403) {
      localStorage.removeItem("M2C:userData");
      window.location.href = `${process.env.PUBLIC_URL}/login`;
    }
    return Promise.reject(error);
  }
);

const downloadNotDelivered = async (campaignId: string, companyId: string): Promise<Blob> => {
  try {
    const response = await axios.get(
      `${API_URL}/sms-reports/export-not-delivered/${campaignId}/${companyId}`,
      {
        responseType: "blob", // Importante para arquivos
        ...getAuthHeaders(),
      }
    );

    return response.data; // Retorna o Blob
  } catch (error) {
    console.error("Erro ao baixar o arquivo:", error);
    throw error;
  }
};

const downloadCampaignMessages = async (campaignId: string, companyId: string): Promise<Blob> => {
  try {
    const response = await axios.get(
      `${API_URL}/sms-reports/export-data/${campaignId}/${companyId}`,
      {
        responseType: "blob", // Importante para arquivos
        ...getAuthHeaders(),
      }
    );

    return response.data; // Retorna o Blob
  } catch (error) {
    console.error("Erro ao baixar o arquivo:", error);
    throw error;
  }
};

export {
  downloadNotDelivered,
  downloadCampaignMessages,
};

